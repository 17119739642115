import { DayCalendarFilterOptionType } from '@kinderlabs-pos/shared-data-type';
import { AdminDatePicker, WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	Typography,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

const today = dayjs();
const thisWeekStart = today.startOf('week').startOf('day');
const thisMonthStart = today.startOf('month').startOf('day');
const rangeDay = 31;
const rangeDayErrorMessage = `날짜 선택 범위는 ${rangeDay}일 이내로 설정해주세요`;
const fromIsLaterThanToErrorMessage = `날짜 선택이 잘못되었습니다.`;
export interface ICalendarSelectDialogProps extends XlDialogProps {
	option: DayCalendarFilterOptionType;
	setOption: (option: DayCalendarFilterOptionType) => void;
}

export const CalendarSelectDialog: React.FC<ICalendarSelectDialogProps> = ({
	open,
	closeDialog,
	option,
	setOption,
}) => {
	const [tempFrom, setTempFrom] = useState(option.from ?? today);
	const [tempTo, setTempTo] = useState(option.to ?? today);

	const [error, setError] = useState<string | null>(null);

	const setSearchTimeFormFilter = () => {
		setOption({ from: tempFrom.startOf('d'), to: tempTo.endOf('d') });
	};

	const handleTempFromChange = (val: Dayjs | null) => {
		if (val) {
			if (val.isAfter(tempTo)) {
				setError(fromIsLaterThanToErrorMessage);
			} else if (tempTo.diff(val, 'day') > rangeDay) {
				setError(rangeDayErrorMessage);
			} else {
				setError(null);
			}
			setTempFrom(val);
		}
	};

	const handleTempToChange = (val: Dayjs | null) => {
		if (val) {
			if (tempFrom.isAfter(val)) {
				setError(fromIsLaterThanToErrorMessage);
			} else if (val.diff(tempFrom, 'day') > rangeDay) {
				setError(rangeDayErrorMessage);
			} else {
				setError(null);
			}

			setTempTo(val);
		}
	};

	return (
		<WithDialog.XlDialog
			scroll='paper'
			open={open}
			closeDialog={closeDialog}>
			<DialogTitle>날짜 설정</DialogTitle>

			<DialogContent>
				<Stack
					spacing={2}
					py={1}>
					<Stack
						direction={'row'}
						spacing={1}>
						<Button
							variant='outlined'
							onClick={() => {
								setTempFrom(dayjs());
								setTempTo(dayjs());
							}}
							size='small'>
							오늘
						</Button>
						<Button
							variant='outlined'
							onClick={() => {
								setTempFrom(dayjs().add(-1, 'd'));
								setTempTo(dayjs().add(-1, 'd'));
							}}
							size='small'>
							어제
						</Button>
						<Button
							variant='outlined'
							onClick={() => {
								setTempFrom(thisWeekStart);
								setTempTo(dayjs());
							}}
							size='small'>
							이번주
						</Button>
						<Button
							variant='outlined'
							onClick={() => {
								setTempFrom(thisMonthStart);
								setTempTo(dayjs());
							}}
							size='small'>
							이번달
						</Button>
					</Stack>
					<Stack
						direction={'row'}
						alignItems={'center'}
						spacing={1}
						mb={1}>
						<AdminDatePicker
							sx={{ flex: 1 }}
							value={tempFrom}
							onChange={handleTempFromChange}
						/>
						<Typography variant={'h5'}>~</Typography>
						<AdminDatePicker
							sx={{ flex: 1 }}
							value={tempTo}
							onChange={handleTempToChange}
						/>
					</Stack>
					{error && <Typography color='error'>{error}</Typography>}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					variant={'contained'}
					disabled={!!error}
					onClick={() => {
						setSearchTimeFormFilter();
						closeDialog && closeDialog();
					}}>
					확인
				</Button>
				<Button
					onClick={closeDialog}
					variant={'outlined'}>
					취소
				</Button>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
