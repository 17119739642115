import { WarningOutlined } from '@ant-design/icons';
import { OrderAPI } from '@kinderlabs-pos/apis/pos';
import {
	CartType,
	ErrorMessageType,
	OrderDetailType,
	OrderType,
	OrderTypeWithDdizisAndExchanges,
	PaymentInfoType,
	PaymentRequestInfoType,
	StoreDetailInfoType,
	StoreInfoType,
} from '@kinderlabs-pos/shared-data-type';
import {
	AppTicketSearchState,
	OnlineTicketSearchState,
	OrderQueryState,
} from '@kinderlabs-pos/state';
import { useAlert, useConfirm } from '@kinderlabs-pos/ui-components';
import { Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import produce from 'immer';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { OrderState, OrderStateType } from '.';
import { GuestVisitQueryState } from '../../GuestVisitQueryState';
import { PaymentQueryState } from '../../PaymentQueryState';
import { PrintReceiptState } from '../../PrintReceiptState';
import { ReceiptQueryState } from '../../ReceiptQueryState';
import { StoreInfoState } from '../../StoreInfoState';
import { TicketQueryState } from '../../TicketQueryState';

/**
 * 첫 결제 POS 전용
 * */
const useCreateOrderPayment = ({ orderType }: { orderType: OrderType['type'] }) => {
	const { cart, kioskUserTelephone } = useAtomValue(OrderState.value);
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const { policy, id: storeId } = storeInfo;
	const posId = StoreInfoState.useCurDeviceInfoForOrder().id;

	const { totalToPay } = OrderState.cart.utils.getAggregate(cart);
	const { remainingPay } = useAtomValue(OrderState.aggregate.payments);

	const { guestMemberInfo } = useAtomValue(OrderState.value);

	const handleOrderComplete = _useHandleOrderComplete({
		storeInfo,
		cart,
	});

	return useMutation(
		async ({ paymentRequestData }: { paymentRequestData?: PaymentRequestInfoType }) => {
			return await OrderAPI.postOrder({
				storeId,
				deviceType: 'POS',
				deviceId: posId,
				isLastPayment: remainingPay === (paymentRequestData ? paymentRequestData.amount : 0),
				orderCreateRequest: {
					storeId,
					posId,
					type: orderType,
					cart: cart,
					guestMemberInfo: guestMemberInfo
						? {
								memberId: guestMemberInfo.memberId,
								telephone: guestMemberInfo.telephone,
								name: guestMemberInfo.name,
						  }
						: undefined,
					isDdiziStartAtPublish: policy.isDdiziStartAtPublish,
					points: (policy.pointRatio / 100) * totalToPay,
					pointMemberUserId: guestMemberInfo?.appUserId,
					kioskUserTelephone,
				},
				payment: paymentRequestData,
				receipt: paymentRequestData?.receipt,
			});
		},
		{
			onSuccess: ({ payment, order }) => {
				handleOrderComplete({ payment, order });
			},
		}
	);
};

/**
 * 첫 결제 KIOSK 전용
 * */
const useCreateKioskOrderPayment = ({
	storeInfo,
	deviceId,
	pointMemberUserId,
	guestMemberInfo,
}: {
	storeInfo: StoreDetailInfoType;
	deviceId: number;
	pointMemberUserId?: string;
	guestMemberInfo?: OrderType['guestMemberInfo'];
}) => {
	const { cart, kioskUserTelephone } = useAtomValue(OrderState.value);
	const orderReducer = useSetAtom(OrderState.value);
	const { totalToPay } = OrderState.cart.utils.getAggregate(cart);

	return useMutation(
		async ({ paymentRequestData }: { paymentRequestData?: PaymentRequestInfoType }) => {
			return await OrderAPI.postOrder({
				storeId: storeInfo.id,
				deviceType: 'KIOSK',
				deviceId: deviceId,
				isLastPayment: true,
				orderCreateRequest: {
					storeId: storeInfo.id,
					posId: deviceId,
					type: 'ORDER',
					cart: cart,
					guestMemberInfo: guestMemberInfo
						? {
								memberId: guestMemberInfo.memberId,
								name: guestMemberInfo.name,
								telephone: guestMemberInfo.telephone,
						  }
						: undefined,
					isDdiziStartAtPublish: storeInfo.policy.isDdiziStartAtPublish,
					points: (storeInfo.policy.pointRatio / 100) * totalToPay,
					pointMemberUserId,
					kioskUserTelephone,
				},
				payment: paymentRequestData,
				receipt: paymentRequestData?.receipt,
			});
		},
		{
			onSuccess: ({ payment, order }) => {
				orderReducer({
					type: 'SET_COMPLETE',
					order,
					onCompleteOrder: () => {},
				});
			},
		}
	);
};

/**
 * 추가 결제 POS 전용
 * */
const useContinueOrderPayment = () => {
	const { cart } = useAtomValue(OrderState.value);
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const { policy, id: storeId } = storeInfo;
	const posId = StoreInfoState.useCurDeviceInfoForOrder().id;
	const { remainingPay } = useAtomValue(OrderState.aggregate.payments);

	const handleOrderComplete = _useHandleOrderComplete({
		storeInfo,
		cart,
	});

	return useMutation(
		async ({
			paymentRequestData,
			orderId,
		}: {
			paymentRequestData?: PaymentRequestInfoType;
			orderId: OrderType['id'];
		}) => {
			return await OrderAPI.postOrder({
				storeId,
				deviceType: 'POS',
				deviceId: posId,
				orderId: orderId,
				isLastPayment: remainingPay === (paymentRequestData ? paymentRequestData.amount : 0),
				orderCreateRequest: undefined,
				payment: paymentRequestData,
				receipt: paymentRequestData?.receipt,
			});
		},
		{
			onSuccess: ({ payment, order }) => {
				handleOrderComplete({ payment, order });
			},
		}
	);
};

/**
 * 주문 수정
 */
const useEditOrderPayment = () => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const { policy, id: storeId } = storeInfo;
	const { cart } = useAtomValue(OrderState.value);
	const posId = StoreInfoState.useCurDeviceInfoForOrder().id;

	const handleOrderComplete = _useHandleOrderComplete({
		storeInfo,
		cart,
		isEditing: true,
	});

	return useMutation(
		async ({
			paymentRequestData,
			orderId,
			newPoints,
		}: {
			paymentRequestData?: PaymentRequestInfoType;
			orderId: OrderType['id'];
			newPoints: number;
		}) => {
			return await OrderAPI.editOrder({
				storeId,
				posId,
				orderId: orderId,
				cart,
				newPoints,
				payment: paymentRequestData,
				receipt: paymentRequestData?.receipt,
			});
		},
		{
			onSuccess: ({ payment, order }) => {
				handleOrderComplete({ payment, order });
			},
		}
	);
};

/**
 * 결산 주문
 */
const useKyulsanOrderPayment = () => {
	const { cart } = useAtomValue(OrderState.value);
	const cartDispatcher = OrderState.cart.useDispatcher();

	return useMutation(
		async ({
			posId,
			paymentRequestData,
			targetDate,
			isRefund,
		}: {
			posId: number;
			paymentRequestData: PaymentRequestInfoType;
			targetDate: Dayjs;
			isRefund: boolean;
		}) => {
			const cartForKyulsan = isRefund
				? produce(cart, (draft) => {
						draft.lines.forEach((cl) => {
							cl.quantity = -cl.quantity;
						});
				  })
				: cart;

			return await OrderAPI.kyulsanOrder({
				storeId: paymentRequestData.storeId,
				posId,
				cart: cartForKyulsan,
				payment: paymentRequestData,
				receipt: paymentRequestData?.receipt,
				targetDate,
			});
		},
		{
			onSuccess: ({ payment, order }) => {
				cartDispatcher({ type: 'CLEAR' });
				OrderQueryState.invalidateQueries();
			},
		}
	);
};

const useChangePointUserMemberId = () => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const { id: storeId } = storeInfo;
	const posId = StoreInfoState.useCurDeviceInfoForOrder().id;
	const orderReducer = useSetAtom(OrderState.value);

	return useMutation(
		async ({ orderId, memberUserId }: { orderId: OrderType['id']; memberUserId: string }) => {
			return OrderAPI.changePointUserMemberId({
				storeId,
				posId,
				orderId,
				memberUserId,
			});
		},
		{
			onSuccess: ({ pointMemberUserId }) => {
				if (pointMemberUserId) {
					OrderQueryState.invalidateQueries();
					orderReducer({ type: 'CHANGE_MEMBER_USER_ID', memberUserId: pointMemberUserId });
				}
			},
		}
	);
};

/**
 * private 하게 사용
 */
const _useHandleOrderComplete = ({
	storeInfo,
	cart,
	isEditing = false,
}: {
	storeInfo: StoreInfoType;
	cart: CartType;
	isEditing?: boolean;
}) => {
	const orderReducer = useSetAtom(OrderState.value);
	const setPrintingDdizis = useSetAtom(OrderState.printing.ddizis.value);
	const setPriningCoupons = useSetAtom(OrderState.printing.exchangeCoupons.value);

	const resetAppTicketSearch = useResetAtom(AppTicketSearchState);
	const resetOnlineTicketSearch = useResetAtom(OnlineTicketSearchState);

	return async ({
		payment,
		order,
	}: {
		payment: PaymentInfoType;
		order: OrderTypeWithDdizisAndExchanges;
	}) => {
		const { ddizis, exchangeCoupons, ...otherOrder } = order;

		// 결제 완성의 경우,
		if (order.status === 'COMPLETED') {
			setPrintingDdizis(ddizis);
			setPriningCoupons(exchangeCoupons);

			orderReducer({
				type: 'SET_COMPLETE',
				order: otherOrder,
				onCompleteOrder: () => {
					resetAppTicketSearch();
					resetOnlineTicketSearch();
				},
			});
		} else {
			// 결제 미완의 경우
			if (isEditing) {
				orderReducer({
					type: 'CLEAR',
				});
			} else {
				orderReducer({
					type: 'SET_START',
					order: otherOrder,
				});
			}
		}

		OrderQueryState.invalidateQueries();

		if (!payment) return;

		orderReducer({
			type: 'PAYMENTS',
			subAction: { type: 'SUBMIT', paymentInfo: payment },
		});

		PaymentQueryState.invalidateQueries();
		ReceiptQueryState.invalidateQueries();
	};
};

/**
 * POS 전용
 */
const usePostponeAndClear = ({ orderType }: { orderType: OrderType['type'] }) => {
	const { cart, id: orderId } = useAtomValue(OrderState.value);
	const clearOrder = useClearOrder();
	const { policy, id: storeId } = useAtomValue(StoreInfoState.curStoreInfo);
	const posId = StoreInfoState.useCurDeviceInfoForOrder().id;
	const { totalToPay } = OrderState.cart.utils.getAggregate(cart);
	const { guestMemberInfo } = useAtomValue(OrderState.value);

	return useMutation(
		async () => {
			if (orderId) {
				// 기존에 존재하는 주문 연기 처리
				await OrderAPI.postponeOrder({
					orderId,
					storeId,
					posId,
				});
			} else {
				// 주문을 먼저 생성함. 이후 결제나 종료 수행
				const result = await OrderAPI.postOrder({
					storeId,
					deviceType: 'POS',
					deviceId: posId,
					isLastPayment: false,
					orderCreateRequest: {
						storeId,
						posId,
						type: orderType,
						cart: cart,
						guestMemberInfo: guestMemberInfo,
						isDdiziStartAtPublish: policy.isDdiziStartAtPublish,
						points: (policy.pointRatio / 100) * totalToPay,
					},
				});

				await OrderAPI.postponeOrder({
					orderId: result.order.id,
					storeId,
					posId,
				});
			}
		},
		{
			onSuccess: () => {
				clearOrder();

				GuestVisitQueryState.invalidateQueries();
				OrderQueryState.invalidateQueries();
				TicketQueryState.invalidateQueries();
			},
		}
	);
};

const useClearOrder = () => {
	const orderReducer = useSetAtom(OrderState.value);

	return () => orderReducer({ type: 'CLEAR' });
};

const useCancelOrderConfirm = (onSuccess?: () => void) => {
	const customConfirm = useConfirm();
	const customAlert = useAlert();
	const printReceipt = PrintReceiptState.use영수증수동출력({ receiptType: 'CANCEL' });
	const storeId = useAtomValue(StoreInfoState.curStoreInfo).id;
	const posId = StoreInfoState.useCurDeviceInfoForOrder().id;

	const cancelOrderMutate = useMutation(
		async ({ order }: { order: OrderDetailType }) => {
			const ret = await OrderAPI.cancelOrder({
				orderId: order.id,
				storeId,
				posId,
			});

			return ret;
		},
		{
			onSuccess: (data) => {
				OrderQueryState.invalidateQueries();
				TicketQueryState.invalidateQueries();

				onSuccess && onSuccess();
				printReceipt({ order: data as OrderStateType, payments: data.payments });
			},
			onError: (e) => {
				customAlert((e as ErrorMessageType).message);
			},
		}
	);

	return (order: OrderDetailType) => {
		customConfirm(
			<Stack spacing={1}>
				<Typography
					color={'error'}
					variant={'h3'}>
					<WarningOutlined />
					{' 주문을 취소하시겠습니까?'}
				</Typography>
				<Typography variant={'subtitle2'}>
					∙ 주문을 취소하면 발급된 티켓이 모두 발급 취소되며 주문 복구가 불가능합니다
				</Typography>
				<Typography
					color={'error'}
					variant={'subtitle2'}>
					∙ 결제수단 변경 등 재결제가 필요한 경우에는 주문을 취소하지 마세요
				</Typography>
			</Stack>,
			async () => cancelOrderMutate.mutate({ order: order })
		);
	};
};

const useCancelOrder = (onSuccess?: () => void) => {
	const customAlert = useAlert();
	const printReceipt = PrintReceiptState.use영수증수동출력({ receiptType: 'CANCEL' });
	const storeId = useAtomValue(StoreInfoState.curStoreInfo).id;
	const posId = StoreInfoState.useCurDeviceInfoForOrder().id;

	const cancelOrderMutate = useMutation(
		async ({ order }: { order: OrderDetailType }) => {
			const ret = await OrderAPI.cancelOrder({
				orderId: order.id,
				storeId,
				posId,
			});

			return ret;
		},
		{
			onSuccess: (data) => {
				OrderQueryState.invalidateQueries();
				TicketQueryState.invalidateQueries();

				onSuccess && onSuccess();
				printReceipt({ order: data as OrderStateType, payments: data.payments });
			},
			onError: (e) => {
				customAlert((e as ErrorMessageType).message);
			},
		}
	);

	return async (order: OrderDetailType) => cancelOrderMutate.mutate({ order: order });
};

const useUpdateOrderMember = () => {
	return useMutation(
		async ({
			orderId,
			memberInfo,
		}: {
			orderId: string;
			memberInfo: OrderType['guestMemberInfo'];
		}) => {
			return await OrderAPI.updateOrderMember({ orderId, memberInfo });
		},
		{
			onSuccess: () => {
				GuestVisitQueryState.invalidateQueries();
				OrderQueryState.invalidateQueries();
				TicketQueryState.invalidateQueries();
			},
		}
	);
};

const useDeleteOrderMember = () => {
	return useMutation(
		async (orderId: string) => {
			return await OrderAPI.deleteOrderMember(orderId);
		},
		{
			onSuccess: () => {
				GuestVisitQueryState.invalidateQueries();
				OrderQueryState.invalidateQueries();
				TicketQueryState.invalidateQueries();
			},
		}
	);
};

export const OrderActions = {
	useCreateOrderPayment,
	useCreateKioskOrderPayment,
	useEditOrderPayment,
	useKyulsanOrderPayment,
	useContinueOrderPayment,
	usePostponeAndClear,
	useCancelOrderConfirm,
	useCancelOrder,
	useChangePointUserMemberId,
	useClear: useClearOrder,
	useUpdateMember: useUpdateOrderMember,
	useDeleteMember: useDeleteOrderMember,
};
