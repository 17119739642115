import { guestMemberApis } from '@kinderlabs-pos/apis/waivers';
import {
	GuestMemberInfoType,
	Pageable,
	VisitUserGendersType,
} from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai';
import { authState } from '../AuthState';
import { OrderQueryState } from '../OrderQueryState';
import { QueryClient } from '../QueryClient';

const guestMemberQueryKyes = createQueryKeys('guest-member', {
	searchByTelephone: (telephone: string) => {
		return {
			queryKey: [telephone],
			queryFn: async () => {
				return await guestMemberApis.getGuestMemberInfo({ type: 'telephone', value: telephone });
			},
		};
	},
	searchById: (id: number) => {
		return {
			queryKey: [id],
			queryFn: async () => {
				return await guestMemberApis.getGuestMemberInfo({ type: 'id', value: id });
			},
		};
	},
	search: ({
		params,
	}: {
		params: {
			storeIdList?: number[];
			page?: number;
			size?: number;
			search?: string;
			from?: Dayjs;
			to?: Dayjs;
			sort?: Pageable['sort'];
			gender?: VisitUserGendersType;
		};
	}) => {
		const storeIdListForManager = useAtomValue(authState.storeIdListForManager);
		return {
			queryKey: [params],
			queryFn: async () => {
				return await guestMemberApis.getGuestMemberInfoList({
					storeIdList: params.storeIdList || storeIdListForManager,
					search: params.search,
					pageable: Pageable.getPageable(params),
					from: params.from,
					to: params.to,
					gender: params.gender,
				});
			},
		};
	},
	detailById: (id?: number) => {
		return {
			queryKey: [id],
			queryFn: async () => {
				if (!id) return null;
				return await guestMemberApis.getGeustMemberDetailInfo(id);
			},
		};
	},
	getRecetnFive: (storeId?: number) => {
		return {
			queryKey: [storeId],
			queryFn: async () => {
				return await guestMemberApis.getRecentFiveInfo(storeId);
			},
		};
	},
	statistics: (memberId: number) => {
		return {
			queryKey: [memberId],
			queryFn: async () => {
				return await guestMemberApis.getGuestMemberStatistics(memberId);
			},
		};
	},
});

const useCreateGuestMemeberInfo = () => {
	return QueryClient.useMutation(
		async (
			userInfo: Partial<
				Omit<
					GuestMemberInfoType,
					| 'id'
					| 'created'
					| 'telephone'
					| 'kinderlabsAgreement'
					| 'storeAgreement'
					| 'marketingAgreement'
				>
			> & { isMarketingAgree: boolean; telephone: string }
		) => {
			return await guestMemberApis.registerGuestMember(userInfo);
		},
		{
			onSuccess: () => {
				invalidateQuries();
			},
		}
	);
};

const useUpdateGuestMemberInfo = () => {
	return QueryClient.useMutation(
		async (
			userInfo: Partial<
				Omit<
					GuestMemberInfoType,
					| 'id'
					| 'stroeId'
					| 'telephone'
					| 'kinderlabsAgreement'
					| 'storeAgreement'
					| 'marketingAgreement'
				>
			> & { isMarketingAgree: boolean; id: number; telephone: string }
		) => {
			await guestMemberApis.updateGuestMemberInfo(userInfo);
		},
		{
			onSuccess: () => {
				invalidateQuries();
			},
		}
	);
};

const useDeleteGuestMemberInfo = () => {
	return QueryClient.useMutation(
		async (idList: number[]) => {
			await guestMemberApis.deleteGuestMemberInfo(idList);
		},
		{
			onSuccess: () => {
				invalidateQuries();
				OrderQueryState.invalidateQueries();
			},
		}
	);
};

type AdjustVisitHistoryRequestType = {
	storeId: number;
	memberId: number;
	visitDate: string;
};

const useCreateVisitHistory = () => {
	return QueryClient.useMutation(
		async (data: AdjustVisitHistoryRequestType) => {
			return await guestMemberApis.createVisitHistory(data);
		},
		{
			onSuccess: () => {
				invalidateQuries();
			},
		}
	);
};

const useDeleteVisitHistory = () => {
	return QueryClient.useMutation(
		async (data: AdjustVisitHistoryRequestType) => {
			return await guestMemberApis.deleteVisitHistory(data);
		},
		{ onSuccess: () => invalidateQuries() }
	);
};

const invalidateQuries = () =>
	QueryClient.origin.invalidateQueries({ queryKey: guestMemberQueryKyes._def });

export const GuestMemberInfoState = {
	keys: guestMemberQueryKyes,
	create: useCreateGuestMemeberInfo,
	update: useUpdateGuestMemberInfo,
	delete: useDeleteGuestMemberInfo,
	visitHistory: {
		create: useCreateVisitHistory,
		delete: useDeleteVisitHistory,
	},
};
